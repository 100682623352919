// Copyright @yucwang 2021

import { Link, PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import { coverImage, 
         container } from "./about.module.css"

const AboutPage: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>BUL | About</title>
    </Helmet>
    <div className={ container }>
      <h2>About</h2>
      <div className={ coverImage }>
        <StaticImage
          src="../../assets/cover_image.jpeg" />
      </div>
      <p>I am Yu-Chen Wang(A.K.A Bulbaberry or Elite_Sheep), currently a third-year Ph.D student in the graphics lab at UC Irvine, advised by Prof. Shuang Zhao. Before that, I worked with Prof. Yung-Yu Chuang at National Taiwan University on forward rendering. In my spare time, I love photography, pokemon, Arsenal and Age of Empire. If you are interested in talking with me, please feel free to drop me an email.</p>
      <h3>Contact</h3>
      <li>Email: yuchew23@uci.edu</li>
      <h3>Links</h3>
      <li><Link to="https://shuangz.com/">Shuang Zhao</Link></li>
      <li><Link to="https://www.csie.ntu.edu.tw/~cyy/">Yung-Yu Chuang</Link></li>
    </div>
  </Layout>
)

export default AboutPage
